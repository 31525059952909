import 'script-loader!slick-carousel';
import fancybox from '@fancyapps/fancybox/dist/jquery.fancybox';

const sideMenu = $('#mySidenav');
window.toggleSideMenu = () => {
    sideMenu.toggleClass('opened');
};

$('.single-item').slick({
    autoplay: true,
    autoplaySpeed: 4000,
});

const $header = $('header');
const $frontEnd = $('.front-end');
const $mobMenu = $('.menu-header');
if ($header.length) {
    const adjustMargin = () => {
        $frontEnd.css('padding-top', `${$header.innerHeight()}px`);
        $mobMenu.css('height', `${$header.innerHeight()}px`);
    };

    $(window).on('resize load', () => {
        adjustMargin();
    });
}

$('#RouteButton').click(function () {
    console.log('test');
    let home = $('#route').val();
    document.location.href = `https://www.google.nl/maps?daddr=Marisstraat+53&saddr=${home}`;
})

$(document).on("scroll", function(){
  if ($(document).scrollTop() > 100){
    $("header").addClass("shrink");
  } else {
    $("header").removeClass("shrink");
  }
});

$(".reference").css({'height':($(".review").height()+'px')});
